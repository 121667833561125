.wrapper {
  min-height: 100vh !important;
}
th {
  outline: none;
}
.content-header {
  span {
    color: #337ab7;
  }
}
.timeline-container {
  padding-left: 15px;
  padding-bottom: 15px;
}
.box-footer {
  .form-control {
    // max-width: 100px;
  }
  .pull-right {
    margin-left: 10px;
  }
}
.table.table-item-detail {
  th,
  td {
    padding-left: 25px;
  }
  th:last-child,
  td:last-child {
    padding-right: 25px;
  }
}
.table th.right,
.table td.right {
  text-align: right;
}

.box-scroll {
  overflow-x: scroll;
}

.form-control.input-xs {
  height: 20px;
  padding: 5px 10px;
  text-align: right;
  max-width: 65px;
}
.center {
  text-align: center;
}

.btn-square {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
  width: 30px;
  padding: 0;
}
.btn-square > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  line-height: 26px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.dataTable tbody tr {
  cursor: pointer;
}
.react-datepicker-wrapper {
  float: right !important;
  // margin-left: 10px;
}
.react-datepicker__input-container input {
  display: block;
  width: 100px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bbb;
    opacity: 1;
  }
}
.timeline-body {
  display: flex;
  // width: 100%;

  p {
    flex-grow: 1;
  }
  img {
    width: 100px;
    height: 100px;
    margin-left: 15px;
    object-fit: cover;
    justify-content: flex-end;
  }
}
.content-header {
  display: flex;
  h1 {
    flex-grow: 1;
  }
  button {
    margin-left: 10px;
  }
}
.nav-tabs-custom > .tab-content {
  padding: 0;
}
.nav-tabs-custom .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
  padding: 5px 10px 10px 10px;
}
.timeline-body.title {
  // font-size: 14px;
  font-weight: 600;
}
.modal.in.block {
  display: block;
}
.modal-body {
  max-height: 75vh;
  overflow-y: scroll;
}

#po-view {
  .po-status {
    padding: 0 15px;
    border-radius: 10px;
    font-size: 22px;
    margin-left: 15px;
  }
}

#po-modal .form-control.input-xs,
#journal-modal .form-control.input-xs {
  max-width: 100%;
  padding: 0 5px;
}

#po-modal table.table-item-detail {
  th,
  td {
    padding: 0;
    input.form-control.input-xs {
      max-width: 100%;
    }
  }
  tr:first-child th {
    text-align: center;
  }
  th:first-child,
  td:first-child {
    width: 50px;
  }
  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 125px;
  }
  th,
  td {
    padding-right: 0;
  }
}

#employeeView {
  .content {
    min-height: unset;
  }
  .tableExpense {
    th:nth-child(1) {
      width: 5%;
    }
    th:nth-child(2) {
      width: 15%;
    }
    th:nth-child(3) {
      width: 10%;
    }
    th:nth-child(4) {
      width: 10%;
    }
  }
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 2px 8px;
}

.table .table-condensed .table-item-detail .table-claim-form {
  .checkbox-claim {
    padding-left: 5px;
  }
  td,
  th {
    padding: 4px;
  }
  td:first-child {
    width: 15%;
  }
  td:nth-child(2) {
    // width: 65%;
  }
  td:nth-child(3) {
    width: 20%;
  }
  td:nth-child(4) {
    width: 5%;
  }
}

.modal-body {
  min-height: 250px;
}

.similarItemTitle {
  padding-top: 25px;
  color: maroon;
  text-align: center;
}

.flex {
  display: flex;
}
#item-modal {
  .select-type {
    width: 30%;
    padding: 0;
    max-width: unset;
  }
  .input-name {
    width: 35%;
    padding: 0;
    max-width: unset;
  }
}
.inputFile {
  position: relative;
  input {
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
  }
}
